/**
 * contactUs.js
 * copyright © 2022 Ania Management
 */
import React, { useState, useEffect } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Select, FormControl, MenuItem } from "@mui/material";
import Home from "../pages/home";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactMap from "../components/reactMap";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/contact.css";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { apiCall } from "../components/apiManager";
import { normalizeInput } from "../components/numberFormatter";
import ReactGA from "react-ga4";
import { Modal, Spinner } from "react-bootstrap";

function ContactUs() {
  //for opening page from top whenever user lands on this page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    typeOfInquiry: "",
    content: "",
  };

  //variables for managing local state
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  let { name, email, phone, typeOfInquiry, content } = formValues;

  //for handling captcha
  const handleVerify = async (response) => {
    if (!response) {
      setCaptchaVerified(true);
    }
    if (response && !captchaVerified) {
      setCaptchaVerified(true);
    }
  };

  //for changing values and update state
  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "phone") {
      value = await normalizeInput(value, phone);
      // setFormValues(prev => ({ ...prev, [name]: value }));
    }

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  //for submiting form on click
  const handleSubmit = async (e) => {
    let { name, email, phone, typeOfInquiry, content } = formValues;
    try {
      ReactGA.event("generate_lead", {
        full_name: name,
        email,
        phone,
        subject: typeOfInquiry,
        message: content,
      });
      e.preventDefault();
      setError(true);
      if (
        !name ||
        !typeOfInquiry ||
        !email ||
        !phone ||
        !content ||
        !captchaVerified
      ) {
        return;
      }

      // if (email && !email.match(
      //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      //   return
      // }

      // if (phone && !phone.match(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/)) {
      //   return
      // }

      let payload = {
        ...formValues,
      };

      if (payload) {
        setLoading(true);
        let data = await apiCall("post", "contactUs", payload, {});

        if (data) {
          // toast.success("Form Successfully submitted!");
        } else {
          // toast.success("Form Successfully submitted!");
          // toast.error(data.message, "Something bad happened");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // toast.success("Form Successfully submitted!");
      // toast.error("Something bad happened");
      throw error;
    } finally {
      setFormValues({
        name: "",
        email: "",
        phone: "",
        typeOfInquiry: "",
        content: "",
      });
      setError(false);
      setCaptchaVerified(false);
      setShowThankYou(true);
    }
  };

  return (
    <div className="main">
      <section className="content-wrapper contact-us">
        <div className="ania-content pt-lg-1 pt-0 pb-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 px-lg-5 border-bottom px-4 order-lg-1 order-3 hide-mobile">
                <div className="main-content py-lg-4 pb-4">
                  <h1 className="page-title page-title-lg mb-0 ">
                    Get in Touch
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 dekstop-hide">
            <div className="embed-responsive embed-responsive">
              <ReactMap
                center={[{ lat: 40.91886955304371, lng: -74.16767144523963 }]}
              />
            </div>
          </div>
          <div className="breadcrumb-width">
            <div className="col-lg-12 mb-lg-4 hide-mobile-1">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="link" component={<Home />}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container-width">
            <div className="main-content">
              <div className="row justify-content-center align-items-top">
                <div className="col-lg-12 mb-lg-4  order-lg-2 order-2 dekstop-hide-1">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/" className="link" component={<Home />}>
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact Us
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-12 order-3 dekstop-hide">
                  <h1 className="page-title page-title-lg mb-0  ">
                    Get in Touch
                  </h1>
                </div>
                <div className="col-lg-12  order-lg-3 order-4">
                  <p className="form-title">Send a Message</p>
                </div>
                <div className="col-lg-7  order-lg-4 order-5">
                  <GoogleReCaptchaProvider
                    reCaptchaKey="6LdF5jceAAAAAMF1RMx5RaNMK9tke3RzRRGEEwX_"
                    // "6LfPKsEeAAAAAES3zCFfW_ywnjmYym8zHKFS_Pc0"
                  >
                    <form className="ania-form">
                      <div className="mb-3">
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${
                            error && !name ? "input-invalid" : ""
                          }`}
                          placeholder="Full Name"
                          id=""
                          value={name}
                          onChange={handleChange}
                          disabled={loading}
                        />
                        <p className="error-msg">
                          {error && !name && "Full Name is required"}
                        </p>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          name="email"
                          className={`form-control ${
                            error && !email ? "input-invalid" : ""
                          }`}
                          placeholder="Your Email"
                          id=""
                          value={email}
                          onChange={handleChange}
                          disabled={loading}
                        />
                        <p className="error-msg">
                          {error && !email
                            ? "Email is required"
                            : error &&
                              email &&
                              !email.match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              )
                            ? "Incorrect email address"
                            : null}
                        </p>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          name="phone"
                          className={`form-control ${
                            error && !phone ? "input-invalid" : ""
                          }`}
                          placeholder="Your Telephone"
                          id=""
                          value={phone}
                          onChange={handleChange}
                        />
                        <p className="error-msg">
                          {error && !phone
                            ? "Number is required"
                            : error && phone && phone.length < 14
                            ? "Invalid phone number"
                            : null}
                        </p>
                      </div>
                      <div className="mb-3">
                        <FormControl
                          className={`form-control ${
                            error && !typeOfInquiry ? "input-invalid" : ""
                          }`}
                        >
                          <Select
                            id="exampleFormControlSelect1"
                            className="selectItemField"
                            name="typeOfInquiry"
                            value={typeOfInquiry}
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            displayEmpty
                            disabled={loading}
                          >
                            <MenuItem
                              className="selectItemField"
                              value={
                                "General Inquiry: I am a current tenant and have a question"
                              }
                            >
                              I am a current tenant and have a question
                            </MenuItem>
                            <MenuItem
                              className="selectItemField"
                              value={
                                "General Inquiry: I am not a current tenant and have a question"
                              }
                            >
                              I am not a current tenant and have a question
                            </MenuItem>
                            <MenuItem
                              className="selectItemField"
                              value={
                                "Media Inquiry: I have a specific media or investment related question"
                              }
                            >
                              I have a specific media or investment related
                              question
                            </MenuItem>
                            <MenuItem
                              className="selectItemField"
                              value=""
                              style={{ display: "none" }}
                            >
                              Subject: Please choose the type of inquiry
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <p className="error-msg">
                          {error && !typeOfInquiry && "Select atleast one"}
                        </p>
                      </div>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          name="content"
                          className={`form-control ${
                            error && !content ? "input-invalid" : ""
                          }`}
                          placeholder="Message"
                          rows="7"
                          value={content}
                          onChange={handleChange}
                          id=""
                          disabled={loading}
                        ></textarea>
                        <p className="error-msg">
                          {error && !content && "Message is required"}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            disabled={loading}
                            className="contact-btn btn btn-primary btn-block btn-ania"
                          >
                            {loading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <p className="error-msg">
                            {error && !captchaVerified && "You are not human"}
                          </p>
                          <ToastContainer />
                        </div>
                        <div className="col-lg-6">
                          <GoogleReCaptcha onVerify={handleVerify} />
                        </div>
                      </div>
                    </form>
                    <Modal centered size="sm" show={showThankYou}>
                      <Modal.Header
                        onHide={() => setShowThankYou(false)}
                        closeButton
                        className="border-0"
                      ></Modal.Header>
                      <Modal.Body className="d-flex flex-column align-items-center">
                        <TaskAltIcon
                          style={{ height: 100, width: 100, color: "#6ec36e" }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            marginTop: 10,
                          }}
                        >
                          Thank You!
                        </p>
                        <p style={{ fontSize: 14 }}>
                          Your submission has been sent.
                        </p>
                      </Modal.Body>
                    </Modal>
                  </GoogleReCaptchaProvider>
                </div>
                <div className="align-left col-lg-5  order-lg-5 order-6">
                  <div className="head-office mt-lg-0 mt-5">
                    <p className="title">head office</p>
                    <p className="company-name">ania</p>
                    <p className="office-address">
                      175 Broadway
                      <br />
                      Paterson, NJ 07505
                    </p>
                    <p className="office-phone ">
                      Phone:{" "}
                      <a style={{ color: "#007CBA" }} href="tel:9735237690">
                        (973) 523-7690
                      </a>
                    </p>
                  </div>
                  <div className="office-hours">
                    <p className="title">office hours</p>
                    <p className="office-day">Monday</p>
                    <p className="office-time">9:00am - 7:00pm EST</p>
                    <p className="office-day">Tuesday</p>
                    <p className="office-time">9:00am - 7:00pm EST</p>
                    <p className="office-day">Wednesday</p>
                    <p className="office-time">9:00am - 7:00pm EST</p>
                    <p className="office-day">Thursday</p>
                    <p className="office-time">9:00am - 7:00pm EST</p>
                    <p className="office-day">Friday</p>
                    <p className="office-time">9:00am - 7:00pm EST</p>
                    <p className="office-day">Saturday</p>
                    <p className="office-time">10:00am - 4:00pm EST</p>
                    <p className="office-day">Sunday</p>
                    <p className="office-time">Closed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 hide-mobile">
            <div className="embed-responsive embed-responsive">
              <ReactMap
                center={[{ lat: 40.91886955304371, lng: -74.16767144523963 }]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
