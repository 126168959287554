//JSON data for about us page template
/**
 * whyPatersonTemplate.js
 * copyright © 2022 Ania Management
 */
import aboutusmobileimg from "./assets/images/whyPatersonBanner.png";
import aboutusdesktopimg from "./assets/images/whyPatersonBanner.png";
import hinchliffe_Rendering from "./assets/images/Hinchliffe_Rendering_BallParkDigest1.jpeg";
import alexander_Rendering from "./assets/images/alexander-hamilton-center-paterson-11.png";
import new_ward_street from "./assets/images/new-ward-street-garage-rendering1.png";

export const WhyPatersonData = [
  {
    pageTitle: "Why Paterson",
    url: "whypaterson",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusmobileimg,
    title: "Parks, restaurants, and a short commute into NYC.",
    p1: "Surrounded by parks and rivers, the affordable city of Paterson offers the conveniences of a big city—such as easy access to transportation, parks and recreational spaces, and restaurants with a wide range of cuisines—while only being a short commute into New York City.",
    p2: "The community of Paterson is committed to bringing new life to the area. Throughout the city, you'll see new construction and rehabilitation of places with historical interest and beauty, including the <u> <a href='https://www.nytimes.com/2023/05/17/sports/baseball/hinchliffe-stadium-new-jersey.html?smid=nytcore-i' target='_blank'> Hinchcliffe Stadium</a></u> and the 397th addition to the National Parks System, the <u> <a href='https://www.nps.gov/pagr/learn/historyculture/the-birthplace-of-the-american-industrial-revolution.htm' target='blank'>Paterson Great Falls National Historical Park</a></u>.",
    p3: "Community areas including sports parks and riverfront walks are underway. Infrastructure investments will make commuting even easier by improving transportation and creating more parking, including electric charging stations. The new emergency communications center will improve emergency services.  ",
    p4: "ANIA Management has chosen to invest in Paterson because of its prime location and the city’s commitment to improving the lives of its community. ANIA continues to apply for building permits in Paterson, which would provide modern, new spaces for tenants and shops. ANIA is known for training and hiring locally, and is involved in the community through the FTI Group and investments in local education. Please visit the <u><a href='/properties'> Properties section</a></u> to learn more about ANIA’s buildings.",

    subHeading2: "Historic Sites and Parks and more!",
    subHeading3: "Hinchcliffe Stadium",
    p5: "<u> <a href='https://www.nytimes.com/2023/05/17/sports/baseball/hinchliffe-stadium-new-jersey.html?smid=nytcore-i' target='_blank'>Hinchcliffe Stadium</a></u> was designated a National Historic Landmark. It was one of the last stadiums standing to house the Negro Baseball League. Larry Doby, who made his debut at the stadium in 1942, would become the first African American player in the American Baseball league. A Paterson native, Larry started with the Cleveland Indians in 1947. The park  is currently under construction and is expected to be completed in November of 2022. ",
    image1: hinchliffe_Rendering,
    image1Detail: "Newly renovated Hinchcliffe Stadium open now.",
    image1DetailDesc: "Image Source: Chris Pedota/NorthJersey.com/USA Today Network",
    p6: "Vista Park Green space and community area for residents. ",
    p7: "Great Falls Revitalization Project which will include the <u><a href='https://www.hamiltonpartnership.org/hamiltoncenter/' target='_blank'>Hamilton Visitor Center</a></u>, according to Mayor Andre Sayegh, “would transform Paterson into a <u><a href='https://www.northjersey.com/story/news/paterson-press/2021/10/08/paterson-nj-great-falls-visitor-center-needs-approval/6037307001/' target='_blank'>tourist destination</a><u/>.”",
    p8: "<u><a href='https://www.tapinto.net/towns/paterson/sections/green/articles/paterson-announces-next-phase-of-river-walk-and-quarry-lawn-project' target='_blank'>Quarry Lawn Park & Colt Ruins</a></u>: “The redevelopment of Quarry Lawn will allow us to transform what is currently dilapidated and dangerous stretches into a vibrant community space for our people. This is a great day for Paterson.”",
    image2: alexander_Rendering,
    image2Detail:
      "Rendering of the proposed Great Falls Revitalization Project visitors center.",
    subHeading4: "Infrastructure improvements",
    p9: "<u><a href='https://patersontimes.com/2021/06/24/paterson-shows-off-almost-6-million-emergency-communications-center/' target='_blank'>Command center</a/></u> for OEM, Fire and Police streamlines security for Paterson",
    p10: "<u><a href='https://www.tapinto.net/towns/paterson/sections/economic-development/articles/demolition-of-ward-street-garage-underway-to-make-space-for-new-state-of-the-art-garage' target='_blank'>Ward Street Garage</a></u> Replacement & Transit Oriented Development at Paterson Train Station: 750+ new parking spots and 150+ mixed use units.",
    p11: "Newly renovated <u><a href='https://www.tapinto.net/towns/paterson/articles/paterson-celebrates-opening-of-northside-library-branch' target='_blank'>Northside Library Branch</a></u> ",
    image3: new_ward_street,
    image3Detail: "Rendering of the proposed Ward Street Garage replacement.",
    subHeading5: "New Housing",
    p12: "Argus Mill/Grandparents Raising Grandchildren: 74 units on the site of an 1800s mill.",
    p13: "Market Square Renovation: 68 units of affordable housing which will include housing for veterans.",
    p14: "Former Greenbaum Furniture Company: Converting into mixed-use housing and infill development to create 152 units of downtown housing.",
    p15: "Fair Street: Development will be 190 units in two separate infill buildings. Advocating for one building to be condo or shared-equity housing.",
    p16: "75 Market Street: A conversion of existing office building into residential units.",
    p17: "Former Armory: Being developed into 136 units of housing plus ground floor commercial units.",
    subHeading6: "More",
    p18: "Grow Mex expansion into Paterson: Brings 60 to 100 new jobs to Paterson",
    p19: "Wide range of <u><a href='https://www.tripadvisor.com/Restaurants-g46718-Paterson_New_Jersey.html' target='_blank'>restaurants</a></u>",
  },
];

// //JSON data for terms of use page template
// import banner from "./assets/images/whyPatersonBanner.png";
// import hinchliffe_Rendering from "./assets/images/Hinchliffe_Rendering_BallParkDigest1.png";
// import alexander_Rendering from "./assets/images/alexander-hamilton-center-paterson-11.png";
// import new_ward_street from "./assets/images/new-ward-street-garage-rendering1.png";

// export const WhyPatersonData = {
//   pageTitle: "Why Paterson",
//   url: "whypaterson",
//   heading: "Why Paterson",
//   bannerImage: banner,
//   subHeading1: "Parks, restaurants, and a 30-minute commute into NYC.",
//   p1: "Surrounded by parks and rivers, the affordable city of Paterson offers the conveniences of a big city—such as easy access to transportation, parks and recreational spaces, and restaurants with a wide range of cuisines—while only being a short, 30-minute commute into New York City.",
//   p2: "The community of Paterson is committed to bringing new life to the area. Throughout the city, you'll see new construction and rehabilitation of places with historical interest and beauty, including the <u> <a href='https://www.hinchliffestadium.com/' target='_blank'> Hinchcliffe Stadium </a> </u> and the 397th addition to the National Parks System, the <u> <a href='https://www.nps.gov/pagr/learn/historyculture/the-birthplace-of-the-american-industrial-revolution.htm' target='blank'> Paterson Great Falls National Historical Park</a> </u>.",
//   p3: "Community areas including sports parks and riverfront walks are underway. Infrastructure investments will make commuting even easier by improving transportation and creating more parking, including electric charging stations. The new emergency communications center will improve emergency services.  ",
//   p4: "ANIA Management has chosen to invest in Paterson because of its prime location and the city’s commitment to improving the lives of its community. ANIA continues to apply for building permits in Paterson, which would provide modern, new spaces for tenants and shops. ANIA is known for training and hiring locally, and is involved in the community through the FTI Group and investments in local education. Please visit the <u><a href='/properties'> Properties section </a></u>to learn more about ANIA’s buildings.",
//   subHeading2: "Historic Sites and Parks and more!",
//   subHeading3: "Hinchcliffe Stadium",
//   p5: "<u> <a href='https://www.hinchliffestadium.com/' target='_blank'>Hinchcliffe Stadium </a></u> was designated a National Historic Landmark. It was one of the last stadiums standing to house the Negro Baseball League. Larry Doby, who made his debut at the stadium in 1942, would become the first African American player in the American Baseball league. A Paterson native, Larry started with the Cleveland Indians in 1947. The park  is currently under construction and is expected to be completed in November of 2022. ",
//   image1: hinchliffe_Rendering,
//   image1Detail: "Rendering of the proposed Hinchcliffe Stadium renovation.",
//   p6: "Vista Park Green space and community area for residents. ",
//   p7: "Great Falls Revitalization Project which will include the <u><a href='https://www.hamiltonpartnership.org/hamiltoncenter/' target='_blank'>Hamilton Visitor Center</a></u>, according to Mayor Andre Sayegh, “would transform Paterson into a <u><a href='https://www.northjersey.com/story/news/paterson-press/2021/10/08/paterson-nj-great-falls-visitor-center-needs-approval/6037307001/' target='_blank'> tourist destination</a><u/>.”",
//   p8: "<u><a href='https://www.tapinto.net/towns/paterson/sections/green/articles/paterson-announces-next-phase-of-river-walk-and-quarry-lawn-project' target='_blank'>Quarry Lawn Park & Colt Ruins</a></u>: “The redevelopment of Quarry Lawn will allow us to transform what is currently dilapidated and dangerous stretches into a vibrant community space for our people. This is a great day for Paterson.”",
//   image2: alexander_Rendering,
//   image2Detail:
//     "Rendering of the proposed Great Falls Revitalization Project visitors center.",
//   subHeading4: "Infrastructure improvements",
//   p9: "<u><a href='https://patersontimes.com/2021/06/24/paterson-shows-off-almost-6-million-emergency-communications-center/' target='_blank'>Command center</a/> </u> for OEM, Fire and Police streamlines security for Paterson",
//   p10: "<u><a href='https://www.tapinto.net/towns/paterson/sections/economic-development/articles/demolition-of-ward-street-garage-underway-to-make-space-for-new-state-of-the-art-garage' target='_blank'>Ward Street Garage</a></u> Replacement & Transit Oriented Development at Paterson Train Station: 750+ new parking spots and 150+ mixed use units.",
//   p11: "Newly renovated <u><a href='https://www.tapinto.net/towns/paterson/articles/paterson-celebrates-opening-of-northside-library-branch' target='_blank'>Northside Library Branch</a></u> ",
//   image3: new_ward_street,
//   image3Detail: "Rendering of the proposed Ward Street Garage replacement.",
//   subHeading5: "New Housing",
//   p12: "Argus Mill/Grandparents Raising Grandchildren: 74 units on the site of an 1800s mill.",
//   p13: "Market Square Renovation: 68 units of affordable housing which will include housing for veterans.",
//   p14: "Former Greenbaum Furniture Company: Converting into mixed-use housing and infill development to create 152 units of downtown housing.",
//   p15: "Fair Street: Development will be 190 units in two separate infill buildings. Advocating for one building to be condo or shared-equity housing.",
//   p16: "75 Market Street: A conversion of existing office building into residential units.",
//   p17: "Former Armory: Being developed into 136 units of housing plus ground floor commercial units.",
//   subHeading6: "More",
//   p18: "Grow Mex expansion into Paterson: Brings 60 to 100 new jobs to Paterson",
//   p19: "Wide range of <u><a href='https://www.tripadvisor.com/Restaurants-g46718-Paterson_New_Jersey.html' target='_blank'>restaurants</a></u>",
// };
