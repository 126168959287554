/**
 * findaunit.js
 * copyright © 2022 Ania Management
 */
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Home from "../pages/home";
import ReactMap from "../components/reactMap";
import { useTheme } from "@mui/material/styles";
import {
  Slider,
  Select,
  FormControl,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { apiCall } from "../components/apiManager";
import { SpinnerCircular } from "spinners-react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import "../assets/css/home.css";
import { Carousel } from "react-bootstrap";

const amenities = ["Gym", "Laundry", "Security", "Parking"];

const initialFilterValues = {
  bedroom: "",
  bath: "",
  city: "",
  // building: "",
  skip: 0,
  limit: 10,
  priceRange: { min: 0, max: 5000 },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 190,
    },
  },
};

function getStyles(name, amenitiesName, theme) {
  return {
    fontWeight:
      amenitiesName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function FindUnit() {
  //for managing diffrent state for filters
  const [loading, setLoading] = useState(true);
  let [cardData, setCardData] = useState([]);
  const [isValue, setIsValue] = useState(false);
  const [request, setRequest] = useState(false);
  const [formValues, setFormValues] = useState(initialFilterValues);
  const [priceValue, setPriceValue] = useState([1500, 5000]);
  const [isSearched, setIsSearched] = useState(false);
  const [amenitiesName, setAmenitiesName] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const theme = useTheme();
  const [width, setWidth] = useState(window.innerWidth);
  const centerLocation = [{ lat: 40.9188696, lng: -74.1676714 }];
  const [showImage, setShowImage] = useState(false);
  const [img, setImg] = useState("");
  const [open, setOpen] = useState(false);
  const [imgCenter, setImgCenter] = useState({
    lat: "",
    lng: "",
  });
  const [imageDetails, setImageDetails] = useState({
    title: "",
    price: "",
    beds: "",
    baths: "",
    sqft: "",
  });

  //function to update map coordinates
  const updateCoordinates = async (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let { GPS_Location, Company_Name } = data[i];
        setCoordinates((prev) => [
          ...prev,
          { lat: GPS_Location[1], lng: GPS_Location[0], name: Company_Name },
        ]);
      }
    } else {
      setCoordinates([]);
    }
  };

  //to scroll to top andd loading default page data from api whenever user lands on this page
  useEffect(() => {
    window.scrollTo(0, 0);

    // getting saved search filters from localstorage
    const savedFilter = JSON.parse(localStorage.getItem("searchFilters"));
    let savedFilterValues = initialFilterValues;
    if (savedFilter) {
      savedFilterValues = {
        bedroom: savedFilter.bedroom,
        bath: savedFilter.bath,
        city: savedFilter.city,
        skip: savedFilter.skip,
        limit: savedFilter.limit,
        priceRange: {
          min: savedFilter.priceRange.min,
          max: savedFilter.priceRange.max,
        },
      };
      if (savedFilter.amenities) {
        savedFilterValues.amenities = savedFilter.amenities;
        setAmenitiesName(JSON.parse(savedFilter.amenities));
      }
      setPriceValue([savedFilter.priceRange.min, savedFilter.priceRange.max]);
      setFormValues(savedFilterValues);
      setIsSearched(savedFilter.isSearched);
    }
    // API call
    async function fetchData() {
      let { data, count } = await apiCall(
        "get",
        "unit_property/findUnit",
        null,
        savedFilterValues
      );
      if (data && data.length > 0) {
        setLoading(false);
        updateCoordinates(data);
        setCardData(data);
        setHasMore(count - (cardData.length + data.length) > 0);
      } else {
        setLoading(false);
        updateCoordinates([]);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  //Infinite scroll on search
  // useEffect(() => {
  //   if (request) {
  //     async function fetchDataOnScrollUp() {
  //       setLoading(true);
  //       let skip = formValues.skip + 10;
  //       setFormValues({ ...formValues, skip: skip });
  //       let { data, count } = await apiCall("get", "unit_property/findUnit", null, { ...formValues, skip: skip });
  //       if (data && data.length > 0) {
  //         setRequest(false);
  //         setCardData([...cardData, ...data]);
  //         updateCoordinates(data);
  //         setHasMore(count - (cardData.length + data.length) > 0);
  //         setLoading(false);
  //       } else {
  //         setRequest(false);
  //         setHasMore(false);
  //         updateCoordinates([]);
  //       }
  //     }
  //     fetchDataOnScrollUp();
  //   }
  //   // eslint-disable-next-line
  // }, [request]);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  let {
    bedroom,
    bath,
    city,
    // building
  } = formValues;

  //andler for multiple selection dropddown
  const handleMultiChange = (event) => {
    const {
      target: { value },
    } = event;

    if (!isValue) {
      setIsValue(true);
    }
    setAmenitiesName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //to navigate to new page on clicking view unit
  const navigate = useNavigate();
  const navigatePage = (id) => {
    let path = `/find-unit/detail/${id}`;
    navigate(path);
  };

  //min value for slider
  const minDistance = 500;

  //for managing filters value and update them in local state
  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (!isValue) {
      setIsValue(true);
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  //function for handling slider values
  const handlePriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setPriceValue([
        Math.min(newValue[0], priceValue[1] - minDistance),
        priceValue[1],
      ]);
      setOpen(true);
    } else {
      setPriceValue([
        priceValue[0],
        Math.max(newValue[1], priceValue[0] + minDistance),
      ]);
      setOpen(true);
    }
    if (!isValue) {
      setIsValue(true);
    }
  };

  function valuetext(value) {
    return `${value}`;
  }

  //function for handling submit button functionality
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      let payload = formValues;

      if (amenitiesName && amenitiesName.length > 0) {
        payload = {
          ...payload,
          amenities: JSON.stringify(amenitiesName),
        };
      }
      payload = {
        ...payload,
        skip: 0,
        priceRange: { min: priceValue[0], max: priceValue[1] },
      };

      if (payload && isValue) {
        updateCoordinates([]);
        setIsSearched(true);
        setIsValue(false);
        setCardData([]);

        // saving search filters on client storage
        localStorage.setItem(
          "searchFilters",
          JSON.stringify({ ...payload, isSearched: true })
        );

        // search api call
        let { data, count } = await apiCall(
          "get",
          "unit_property/findUnit",
          null,
          payload
        );

        if (data && data.length > 0) {
          setLoading(false);
          setCardData(data);
          updateCoordinates(data);
          setHasMore(count - (cardData.length + data.length) > 0);
        } else {
          setLoading(false);
          setCardData([]);
          updateCoordinates([]);
          setHasMore(false);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSlider = (e) => {
    setOpen(!open);
  };

  const onMouseEnter = (img, lat, lng, details) => {
    setImgCenter({ lat: lat, lng: lng });
    setShowImage(true);
    setImg(img);
    setImageDetails({
      ...imageDetails,
      title: details.title,
      price: details.price,
      beds: details.beds,
      baths: details.baths,
      sqft: details.sqft,
    });
  };

  const firstEvent = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if (bottom && hasMore) {
      setRequest(true);
    }
  };

  const renderCarousal = useMemo(() => {
    return (item) => {
      if (!item?.unitImages.length) {
        return (
          <img
            src={item?.imagePrimary || "/assets/images/imageNotAvailable.png"}
            className={"img-fluid unit-card-img absentImageThumb"}
            alt=""
          />
        );
      }
      const arr = [
        ...(item?.imagePrimary && [item?.imagePrimary]),
        ...item?.unitImages?.map((x) => x["Unit_NOTE_Images::ArchiveURL"]),
      ];
      if (arr.length === 1) {
        return (
          <img
            src={arr[0] || "/assets/images/imageNotAvailable.png"}
            className={"img-fluid unit-card-img absentImageThumb"}
            alt=""
          />
        );
      }
      return (
        <Carousel
          indicators={false}
          touch={true}
          interval={3000}
          onClick={(e) => e.stopPropagation()}
        >
          {arr?.map((x) => (
            <Carousel.Item>
              <img
                style={{cursor: "default"}}
                src={x || "/assets/images/imageNotAvailable.png"}
                className={
                  item?.propertyImages && item?.propertyImages.length > 0
                    ? "img-fluid unit-card-img"
                    : "img-fluid unit-card-img absentImageThumb"
                }
                alt=""
              />
            </Carousel.Item>
          ))}
        </Carousel>
      );
    };
  });

  return (
    <div className="main">
      <section className="content-wrapper find-a-unit">
        <div className="ania-content pt-lg-1 pt-0">
          <div className="container-fluid px-0">
            <div className="main-content">
              <div className="col-lg-12 px-lg-5 px-4">
                <h1 className="page-title page-title-lg mb-0">Find a Unit</h1>
              </div>
            </div>
            <div className="col-lg-12 mb-lg-4 px-lg-5 border-top breadcrumb-nav">
              <nav aria-label="breadcrumb">
                {isSearched && width <= 990 ? (
                  <ol className="breadcrumb px-2">
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      onClick={() => setIsSearched(false)}
                    >
                      Back to Search
                    </li>
                  </ol>
                ) : (
                  <ol className="px-1 breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/" className="link" component={<Home />}>
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Find a Unit
                    </li>
                  </ol>
                )}
              </nav>
            </div>
            <div
              className={
                "col-lg-12 px-lg-5 search-unit d-lg-block " +
                (isSearched ? "d-none" : "")
              }
            >
              <div className="row">
                <div className="search-box col">
                  <FormControl className="search-option" sx={{ width: "100%" }}>
                    <label className="pl-3">beds</label>
                    <Select
                      name="bedroom"
                      className="form-control border-0"
                      value={bedroom}
                      onChange={handleChange}
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <div className="form-control-options">Any</div>
                      </MenuItem>
                      <MenuItem value={"1"}>
                        <div className="form-control-options">1</div>
                      </MenuItem>
                      <MenuItem value={"2"}>
                        <div className="form-control-options">2</div>
                      </MenuItem>
                      <MenuItem value={"3"}>
                        <div className="form-control-options">3</div>
                      </MenuItem>
                      <MenuItem value={"4"}>
                        <div className="form-control-options">4</div>
                      </MenuItem>
                      <MenuItem value={"5"}>
                        <div className="form-control-options">5+</div>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="search-box col">
                  <FormControl className="search-option" sx={{ width: "100%" }}>
                    <label className="pl-3">bath</label>
                    <Select
                      className="form-control border-0"
                      name="bath"
                      value={bath}
                      onChange={handleChange}
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <div className="form-control-options">Any</div>
                      </MenuItem>
                      <MenuItem value={"1"}>
                        <div className="form-control-options">1</div>
                      </MenuItem>
                      <MenuItem value={"2"}>
                        <div className="form-control-options">2</div>
                      </MenuItem>
                      <MenuItem value={"3"}>
                        <div className="form-control-options">3</div>
                      </MenuItem>
                      <MenuItem value={"4"}>
                        <div className="form-control-options">4</div>
                      </MenuItem>
                      <MenuItem value={"5"}>
                        <div className="form-control-options">5+</div>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="search-box col">
                  <FormControl className="search-option" sx={{ width: "100%" }}>
                    <label className="pl-3">city</label>
                    <Select
                      className="form-control border-0"
                      name="city"
                      value={city}
                      onChange={handleChange}
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <div className="form-control-options">Any</div>
                      </MenuItem>
                      <MenuItem value={"Paterson"}>
                        <div className="form-control-options">Paterson</div>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="search-box col">
                  <div className="search-option" style={{ height: "100%" }}>
                    <label className="pl-3">monthly rent</label>
                    <div
                      className="pl-3 pt-2 pr-2 margin"
                      style={{ cursor: "pointer" }}
                      onClick={handleSlider}
                    >
                      <KeyboardArrowDownOutlinedIcon
                        style={
                          open
                            ? {
                                transform: "rotate(180deg)",
                                float: "right",
                                color: "rgba(0, 0, 0, 0.54)",
                              }
                            : { float: "right", color: "rgba(0, 0, 0, 0.54)" }
                        }
                      />
                      <p
                        style={{
                          margin: "0",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        {priceValue &&
                        priceValue[0] === 1500 &&
                        priceValue[1] === 5000
                          ? "Min-Max"
                          : `$${priceValue[0]}-$${priceValue[1]}`}
                      </p>
                    </div>
                    {open ? (
                      <div className="w-100 px-3 monthly-rent">
                        <Slider
                          style={{ color: "#757575" }}
                          getAriaLabel={() => "Minimum distance"}
                          value={priceValue}
                          onChange={handlePriceChange}
                          valueLabelDisplay="auto"
                          onBlur={handleSlider}
                          getAriaValueText={valuetext}
                          disableswaps="true"
                          min={1500}
                          max={5000}
                          step={500}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col search-box">
                  <FormControl className="search-option" sx={{ width: "100%" }}>
                    <label className="pl-3">amenities</label>
                    <Select
                      className="form-control border-0"
                      name="ameneties"
                      multiple
                      displayEmpty
                      value={amenitiesName}
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                      onChange={handleMultiChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <div className="form-control-options">None</div>
                          );
                        }
                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <div className="form-control-options">None</div>
                      </MenuItem>
                      {amenities.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, amenitiesName, theme)}
                        >
                          <div className="form-control-options"> {name}</div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="search-box col">
                  <button
                    className="btn btn-secondary btn-block"
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: "#9D9D9D",
                      borderColor: "#9D9D9D",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div
              className={
                "col-lg-12 pl-lg-5 pr-lg-0 px-0 d-lg-block " +
                (isSearched ? "d-block" : "d-none")
              }
            >
              <div className="d-lg-flex">
                <div onScroll={firstEvent} className="scroll-view fixed-card">
                  {cardData && cardData.length > 0 ? (
                    cardData.map((val, i) => (
                      <div
                        key={i}
                        className="properties-card"
                        onClick={() => navigatePage(val?.zzID)}
                        onMouseEnter={() =>
                          onMouseEnter(
                            val?.imagePrimaryThumb,
                            val?.GPS_Location[1],
                            val?.GPS_Location[0],
                            {
                              title: val?.Company_Name,
                              price: `$${val?.MonthlyRent}/mo`,
                              beds: val?.Stat_BedroomCount,
                              baths: val?.Stat_BathroomCount,
                              sqft: val?.Stat_SquareFootage,
                            }
                          )
                        }
                        onMouseLeave={() => setShowImage(false)}
                      >
                        {val?.isCarousel && val?.isCarousel === "1" ? (
                          renderCarousal(val)
                        ) : (
                          <img
                            src={
                              val?.imagePrimary ||
                              (val?.unitImages?.length &&
                                val?.unitImages[0][
                                  "Unit_NOTE_Images::ArchiveURL"
                                ]) ||
                              "/assets/images/imageNotAvailable.png"
                            }
                            className={
                              val?.propertyImages &&
                              val?.propertyImages.length > 0
                                ? "img-fluid unit-card-img"
                                : "img-fluid unit-card-img absentImageThumb"
                            }
                            alt=""
                          />
                        )}

                        {val?.Status === "Vacant" ? (
                          <span
                            style={{ cursor: "pointer" }}
                            className="property-badge nr"
                          >
                            {" "}
                            now renting
                          </span>
                        ) : null}
                        <div
                          className="propterties-card-body"
                          style={{ cursor: "pointer" }}
                        >
                          <p className="location mb-0">
                            <span
                              className="city"
                              style={{ textTransform: "capitalize" }}
                            >
                              {val && val?.Address_City
                                ? val?.Address_City.toLowerCase()
                                : ""}
                            </span>
                            <span>&nbsp;</span>
                            <span className="address">
                              {/* {val && val?.propertySize === "L" && val?.Address_Address01_finalDisply ? val?.Address_Address01_finalDisply.toLowerCase() : val?.Address_State} */}
                              {val?.Address_State}
                            </span>
                          </p>
                          {val?.Stat_BedroomCount || val?.Stat_BathroomCount ? (
                            <p className="type">
                              {val?.Stat_BedroomCount} Bedroom,{" "}
                              {val?.Stat_BathroomCount} Bath
                            </p>
                          ) : (
                            ""
                          )}
                          <div className="price-and-availability mb-1">
                            <div className="row px-3 justify-content-between align-items-center">
                              <div className="price">
                                {val && val?.MonthlyRent ? (
                                  <div>
                                    <p className="net-price">Starting at</p>
                                    <p className="value">
                                      {val && val?.MonthlyRent
                                        ? `$${val?.MonthlyRent.toFixed().replace(
                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                            "$1,"
                                          )}`
                                        : ""}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="availability">
                                <p className="net-availability">Availability</p>
                                <p className="value">{val?.Status}</p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-outline-none text-uppercase"
                              onClick={() => navigatePage(val?.zzID)}
                            >
                              view unit
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : !loading ? (
                    <div className="no-data-found">No Data Found !</div>
                  ) : (
                    ""
                  )}
                  {loading && (
                    <div style={{ display: "flex" }}>
                      <SpinnerCircular
                        style={{ width: "30%", height: "50vh" }}
                      />
                    </div>
                  )}
                </div>
                {cardData.length > 0 && (
                  <div className="w-100 px-0 ml-15">
                    <div className="embed-responsive embed-responsive">
                      <ReactMap
                        center={
                          coordinates && coordinates.length > 0
                            ? coordinates
                            : centerLocation
                        }
                        showImage={showImage}
                        imgCenter={imgCenter}
                        img={img}
                        imageDetails={imageDetails}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FindUnit;
