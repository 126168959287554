//JSON data for about us page template

/**
 * constructionLayout.js
 * copyright © 2022 Ania Management
 */

import aboutusmobileimg from "./assets/images/VanHautenStBirdView.png";
import aboutusdesktopimg from "./assets/images/VanHautenStBirdView.png";

export const constructionData = [
  {
    pageTitle: "Construction",
    url: "construction",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusmobileimg,
    title: "Building modern homes using local contractors and suppliers.",
    p1: "ANIA Management runs its own construction company, employing xxx Paterson locals, many of which were trained through the programs that ANIA supports. ",
    p2: "The construction company not only maintains existing units, but rehabilitates any units coming back on the market in addition to actively building new, modern living spaces that include 24/7 security, HVAC and kitchens with stainless steel appliances and quartz countertops. The construction business, in conjunction with the property management company, is continuing to apply for and acquire permits to build within the city of Paterson and select other cities.",
    p3: "Please check out ANIA’s featured properties <u> <a href='/properties'> here</a> </u>",
  },
];
