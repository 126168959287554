/**
 * burger.js
 * copyright © 2022 Ania Management
 */
import React from "react";
import "../assets/css/nav.css";

const Burger = ({ status, handleNav }) => {
  return (
    <div className="burger_container" role="button" onClick={handleNav}>
      <i className={status}></i>
      <i className={status}></i>
      <i className={status}></i>
    </div>
  );
};

export default Burger;
