/**
 * faq.js
 * copyright © 2022 Ania Management
 */

export const faqData = [
  {
    category: "Prospective Tenants",
    questionArray: [
      {
        question: "How do I give notice about moving out?",
        answer:
          "We require 30 days' notice if you plan on moving out at the end of your lease. Please visit our office at 175 Broadway in Paterson to fill out a 30-day notice form.",
      },
      {
        question: "How do I make changes to my lease?",
        answer:
          "Changes are only allowed once your lease term is up. At that point, we require notarized letters of everyone affected by the lease.",
      },
      {
        question:
          "When will I receive my security deposit after returning the keys?",
        answer:
          "You'll receive any security deposit due back to you within 30 days of returning your keys, as long as you've provided us with your new mailing address.",
      },
      {
        question: "Can I relocate to another apartment?",
        answer:
          "You can relocate when your lease term is up, as long as you qualify for the new apartment.",
      },
      {
        question: "How do I get in touch with the office after hours? ",
        answer:
          "Please use the tenant portal to get in contact with staff after hours. For urgent matters, call the 24/7 security command center at <a href='tel:9739686393'> 973-968-6393 </a>.",
      },
      {
        question: "Who do I call for urgent matters?",
        answer:
          " For issues that require immediate attention, please call the 24/7 command center at <a href='tel:9739686393'>973-968-6393</a>.",
      },
      {
        question: "How do I apply to live in an apartment?",
        answer:
          "Please visit our office at 175 Broadway in Paterson to fill out a rental application with a rental agent.",
      },
      {
        question: "How long is a lease term? ",
        answer:
          "Lease terms are set for 1 year and then convert to a month-by-month lease.",
      },
      {
        question: "Can I terminate my lease early? ",
        answer:
          "We don't allow early termination of leases. If you have a special circumstance, please reach out to the staff using the tenant portal.",
      },
      {
        question: "Are pets allowed?",
        answer:
          "All pets must be approved in advance. If approved, there is a $500 pet deposit.",
      },
      {
        question: "What are the requirements to rent?",
        answer:
          "Your monthly net household income must be 3 times the rental amount with no history of evictions.",
      },
      {
        question:
          "Can I add a co-signer to qualify for the income requirement?",
        answer:
          "Everyone applying must be living in the apartment and will be legally attached to the lease.",
      },
    ],
  },
  {
    category: "FTI Group",
    questionArray: [
      {
        question: "What is the FTI?",
        answer:
          "The Florio Tenants Initiative was created to provide City of Paterson residents with a platform to become partners in providing a wealthy quality of life, through programming, events, and an organized residents council. Through these wrap around programs, city residents are empowered to become a positive force within their immediate community, by participating in programming that enhances the quality of their lives. These service-based programs provides city residents with access to services, ranging from health and wellness (physical and mental) to arts and literacy programs for youth. The F.T.I. understands that in order to have healthy communities, there must be an investment in the “whole” person of the community. The F.T.I. is dedicated to addressing the deficiencies of the community- one program, one resident, at a time. ",
      },
      {
        question: "What services does the FTI offer?",
        answer:
          "<u><a href='https://www.ftigroup.org/tutor-me-please' target='_blank'>Tutor Me Please</a></u> </br><u><a href='https://www.ftigroup.org/new-page-35' target='_blank'>I CAN!</a></u> </br><u><a href='https://www.ftigroup.org/new-page-68' target='_blank'>Mind & Spirit Health.</a></u> </br><u><a href='https://www.ftigroup.org/new-page-79' target='_blank'>Wealth N Health</a></u> </br><u><a href='https://www.ftigroup.org/new-page-61' target='_blank'>Entrepreneurs Rock</a></u> </br><u><a href='https://www.ftigroup.org/new-page-81' target='_blank'>Yoga by Ema</a></u> </br><u><a href='https://www.ftigroup.org/new-page-4' target='_blank'>I Got The Deed</a></u> ",
      },
      {
        question: "How do I know which days the programs run?",
        answer:
          "<u><a href='https://www.ftigroup.org/new-page-1' target='_blank'>The FTI calendar</a></u> features the class schedules with location information.",
      },
      {
        question: "How much does it cost to register for programs?",
        answer:
          "All FTI programs are free of charge to Paterson, NJ residents.",
      },
      {
        question: "How can I register to attend the FTI Group programs?",
        answer:
          "Its simple! From the <u><a href='https://www.ftigroup.org' target='_blank'>homepage</a></u>, click “All Programs”. Choose the program that you want to register for, click the ad and register.",
      },
      {
        question: "How can I contact the FTI Group?",
        answer: "<a href='mailto:info@ftigroup.org'>info@ftigroup.org</a>",
      },
    ],
  },

  {
    category: "Maintenance",
    questionArray: [
      {
        question: "I am a Tenant, where do i raise maintenance issues? ",
        answer: "Please use Tenant Portal. ",
      },
      {
        question: "I am a neighbor, whom do i contact?",
        answer: "Please call us at <a href='tel:9739686393'> 973-968-6393</a>",
      },
    ],
  },
  {
    category: "Security",
    questionArray: [
      {
        question: "Who do I call for urgent matters?",
        answer:
          "For issues that require immediate attention, please call the 24/7 command center at <a href='tel:9739686393'>973-968-6393</a>.",
      },
      {
        question: "What is the security number?",
        answer: "<a href='tel:9739686393'>973-968-6393</a>",
      },
      {
        question: "What if I have an emergency but do not feel endangered? ",
        answer:
          "The security team handle all types of emergencies and relays the message to the correct people/departments.",
      },
      {
        question: "Will security be at my building 24/7?",
        answer:
          "No, we have multiple guards patrolling all of our locations 24/7. If you need the presence of a security guard please call the security number and they will send a guard to you.",
      },
      {
        question:
          "If I have an issue with another tenant, will the security guard tell that tenant who called?",
        answer: "No, all complaints about tenants will be anonymous.",
      },
      {
        question: "Is the security team the same as the police department?",
        answer:
          "No, although our team takes their job very seriously we do recommend tenants call the Police department if necessary.",
      },
      {
        question: "Does the security team work with the Police department?",
        answer:
          "Yes, the security team offers everything they can to help the police department in certain cases.",
      },
    ],
  },
  {
    category: "Others",
    questionArray: [
      {
        question: "I have additional questions, whom do I contact?",
        answer:
          "Please email us at <a href=''mailto:nquiry@aniamanagement.com'>inquiry@aniamanagement.com</a>",
      },
    ],
  },
];
