/**
 * reactMap.js
 * copyright © 2022 Ania Management
 */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import logo from "../assets/images/aniamarker.svg";
import beforeHover from "../assets/images/Group 140.svg";
// import hoverLogo from "../assets/images/aniamarker-Orange.svg"
import hoverLogo from "../assets/images/Group 141.svg";

function ReactMap(props) {
  //for checking url to change marker
  const { pathname } = useLocation();
  let url = pathname.split("/");
  url = url[1];
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props) {
      setData([...props.center]);
    }
  }, [props]);

  const mapKey = "AIzaSyDWZ7Su0Zjdr_izSAJ6EOmqhvX6ujSSvPk";
  //for adding marker in maps
  const Marker = (props) => {
    return (
      <div
        className="marker"
        title={url.toLowerCase() === "!properties" ? props.name : ""}
      >
        {(url && url.toLowerCase() === "find-unit") ||
        url.toLowerCase() === "properties" ? (
          <div className="google-map-marker-body">
            <img
              className={"hover-marker"}
              src={props.changeMarker ? hoverLogo : beforeHover}
              alt={props.name}
              width="35"
              height="35"
            />
            {url.toLowerCase() === "properties" && (
              <div className="google-map-marker-tooltip">
                <img src={props.image} width="70" height="auto" alt="" />
                <span className="gmap-marker-name">{props.name}</span>
              </div>
            )}
          </div>
        ) : (
          <img src={logo} alt={props.name} width="50" height="35" />
        )}
      </div>
    );
  };

  const Image = (props) => (
    <div className="selection_room">
      <div className="main_room">
        <div className="room">
          <img
            src={props.img}
            alt=""
            width={50}
            height={50}
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="room_details">
          {/* <p>
            <b>{props.imagedetails.title}</b>
          </p> */}
          <p>{props.imagedetails.price}</p>
          <p>
            {props.imagedetails.beds} bds {props.imagedetails.baths} ba
          </p>
        </div>
      </div>
    </div>
  );

  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };

  return (
    <>
      <GoogleMapReact
        className="embed-responsive-item"
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={{ lat: 40.91886955304371, lng: -74.16767144523963 }}
        defaultZoom={13}
        distanceToMouse={distanceToMouse}
        options={{ maxZoom: 16, zoomControlOptions: { position: 1 } }}
      >
        {data && data.length > 0
          ? data.map((val, i) => (
              <Marker
                key={i}
                lat={val.lat}
                lng={val.lng}
                name={val && val.name ? val.name : "Ania Management"}
                image={val.image}
                changeMarker={
                  props.showImage &&
                  props.imgCenter.lat === val.lat &&
                  props.imgCenter.lng === val.lng
                    ? true
                    : false
                }
              />
            ))
          : null}
        {props.showImage ? (
          <Image
            lat={props.imgCenter.lat}
            lng={props.imgCenter.lng}
            img={props.img}
            imagedetails={props.imageDetails}
          />
        ) : null}
      </GoogleMapReact>
    </>
  );
}

export default ReactMap;
