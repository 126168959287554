//JSON data for properties details page
/**
 * propertyDetailsData.js
 * copyright © 2022 Ania Management
 */
import bannerImage185 from "./assets/images/banner185.png";
import bannerImage198 from "./assets/images/banner198.webp";
import bannerImage425 from "./assets/images/banner425.webp";
import bannerImage216 from "./assets/images/banner216.webp";
import bannerImage449 from "./assets/images/banner449East.jpg";
import bannerImage154 from "./assets/images/banner154ham.png";
import bannerImage159 from "./assets/images/banner154ham.png";
import bannerImage34 from "./assets/images/banner34Auburn.png";

import oneBR185 from "./assets/images/185-1BR.svg";
import twoBR185 from "./assets/images/185-2BR.svg";
import oneBR198 from "./assets/images/198onefloorplan.svg";
import twoBR198 from "./assets/images/198twofloorplan.svg";
import oneBR425 from "./assets/images/425onefloorplan.svg";
import twoBR425 from "./assets/images/425twofloorplan.svg";
import oneBR216 from "./assets/images/216onefloorplan.svg";
import twoBR216 from "./assets/images/216twofloorplan.svg";
import oneBR449 from "./assets/images/449East-1BR.svg";
import twoBR449 from "./assets/images/449East-2BR.svg";
import oneBR154 from "./assets/images/154_Hamilton_Map_1BR.svg";
import oneBR36 from "./assets/images/36_Auburn_Map_1BR.svg";

import Gallery185One from "./assets/images/property-gallery1.webp";
import Gallery185Two from "./assets/images/property-gallery2.webp";
import Gallery185Three from "./assets/images/property-gallery3.webp";
import Gallery185Four from "./assets/images/property-gallery4.webp";
import Gallery198One from "./assets/images/198property-gallery1.webp";
import Gallery198Two from "./assets/images/198property-gallery2.webp";
import Gallery198Three from "./assets/images/198property-gallery3.webp";
import Gallery198Four from "./assets/images/198property-gallery4.webp";
import Gallery425One from "./assets/images/425property-gallery1.webp";
import Gallery425Two from "./assets/images/425property-gallery2.webp";
import Gallery425Three from "./assets/images/425property-gallery3.webp";
import Gallery425Four from "./assets/images/425property-gallery4.webp";
import Gallery216One from "./assets/images/216property-gallery1.webp";
import Gallery216Two from "./assets/images/216property-gallery2.webp";
import Gallery216Three from "./assets/images/216property-gallery3.webp";
import Gallery216Four from "./assets/images/216property-gallery4.webp";

import gallery449_1 from "./assets/images/gallery449_1.png";
import gallery449_2 from "./assets/images/gallery449_2.png";
import gallery449_3 from "./assets/images/gallery449_3.png";
import gallery449_4 from "./assets/images/gallery449_4.png";

import gallery154_1 from "./assets/images/gallery154_1.png";
import gallery154_2 from "./assets/images/gallery154_2.png";
import gallery154_3 from "./assets/images/gallery154_3.png";
import gallery154_4 from "./assets/images/gallery154_4.png";

import gallery159_1 from "./assets/images/gallery159_1.png";
import gallery159_2 from "./assets/images/gallery159_2.png";
import gallery159_3 from "./assets/images/gallery159_3.png";
import gallery159_4 from "./assets/images/gallery159_4.png";

import gallery36_1 from "./assets/images/gallery36_1.png";
import gallery36_2 from "./assets/images/gallery36_2.png";
import gallery36_3 from "./assets/images/gallery36_3.png";
import gallery36_4 from "./assets/images/gallery36_4.png";

export const propertyData = [
  {
    bannerImage: bannerImage185,
    category: "185broadway",
    city: "Paterson, NJ 07505",
    address: "185 Broadway",
    price1: "$1,650",
    price2: "$2,000",
    area1: "600 Sq. Ft and up",
    amenities1: "1 Bathroom / 1 Parking",
    area2: "856 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Centrally located near downtown Paterson, 185 Broadway is a fresh apartment complex that offers one and two bedroom apartments.",
    highlight2:
      "With laundry facilities, walk-in closet and HVAC to keep you cool during summer months, it's perfect for anyone looking for easy access to public transportation, local shops and groceries, museums, schools, churches and more.",
    featureList1: [
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Bike storage",
      "Entrance lobby",
      "Package room",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern – one and two bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR185,
    floorPlanImage2: twoBR185,
    nearByContent:
      "Live here. Go anywhere. 185 Broadway is ideally located a quick walk to the train stop and close to schools, restaurants, downtown and more. The soon to be fully renovated Hinchliffe Stadium is also nearby.",
    photoGalleryImages: [
      Gallery185One,
      Gallery185Two,
      Gallery185Three,
      Gallery185Four,
    ],
    center: [
      {
        lat: 40.91910236750575,
        lng: -74.16706131186885,
        name: "185 Broadway",
      },
    ],
    similarProperty: [
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage198,
        name: "198 Lafayette st",
        propertyName: "198lafayette",
        neighbour: "RIVERSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage425,
        name: "425 11th Ave",
        propertyName: "425eleventh",
        neighbour: "EASTSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage216,
        name: "216 spring st",
        propertyName: "216springst",
        neighbour: "SOUTH PATERSON",
      },
    ],
    nearByLocation: [
      {
        link: "https://goo.gl/maps/LPdaSqAp9tnB48w89",
        src: "/assets/images/198neigh1.webp",
        name: "Paterson Station – 0.4 miles",
      },
      {
        link: "https://goo.gl/maps/1NE5FAGMj7g7MDLm6",
        src: "/assets/images/198neigh2.webp",
        name: "Proximity of I 80 Entrance Ramp – 0.8 miles",
      },
      {
        link: "https://goo.gl/maps/3tbD5U9vUwhDZgeb9",
        src: "/assets/images/198neigh4.webp",
        name: "Costambar (Domincan Restaurant) – 1.3 miles",
      },
      {
        link: "https://goo.gl/maps/4bV8aqQGcNPWWsvV9",
        src: "/assets/images/img-4.png",
        name: "Broadway Pizza – 0.3 miles",
      },
      {
        link: "https://goo.gl/maps/AhwvSKBLmb3bHRwN8",
        src: "/assets/images/img-5.png",
        name: "Passaic County Community College – 0.1 miles",
      },
      {
        link: "https://goo.gl/maps/EtWLULdZuDbh5awa9",
        src: "/assets/images/img-6.png",
        name: "Hinchliffe Stadium – 1.1 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage198,
    category: "198lafayette",
    city: "Paterson, NJ 0750",
    address: "198 Lafayette st",
    price1: "$1,800",
    price2: "$2,200",
    area1: "664 Sq. Ft and up",
    amenities1: "1 Bathroom / 1 Parking",
    area2: "907 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Welcome to 198 Lafayette St! With 24/7 security surveillance for added safety, laundry and community rooms on each floor, you can enjoy community building, convenience and security!",
    highlight2:
      "This modern apartment complex offers a beautiful entrance lobby, package room, and optional paid parking spot. Each unit has HVAC to keep you cool in the summer and warm in the winter.",
    highlight3:
      "The kitchen features quartz counter tops, stainless steel appliances, and vinyl flooring for a modern look with easy clean up.",
    featureList1: [
      "Community area on each floor",
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Entrance lobby",
      "Package room",
      "Laundry room on each floor",
      "Bike storage",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern – one and two bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR198,
    floorPlanImage2: twoBR198,
    nearByContent:
      "Convenient to schools, highway 20 and public transportation into NYC, 198 Lafayette is located north of Broadway in Paterson. Walking distance to dining, shopping and community parks.",
    photoGalleryImages: [
      Gallery198One,
      Gallery198Two,
      Gallery198Three,
      Gallery198Four,
    ],
    center: [
      {
        lat: 40.92519568821805,
        lng: -74.15703640211706,
        name: "198 Lafayette Street",
      },
    ],
    similarProperty: [
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage185,
        name: "185 Broadway",
        propertyName: "185broadway",
        neighbour: "SAND HILL",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage425,
        name: "425 11th Ave",
        propertyName: "425eleventh",
        neighbour: "EASTSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage216,
        name: "216 spring st",
        propertyName: "216springst",
        neighbour: "SOUTH PATERSON",
      },
    ],
    nearByLocation: [
      {
        link: "https://goo.gl/maps/pnpTcW2HYq7Qpv449",
        src: "/assets/images/198neigh1.webp",
        name: "Paterson Station – 1.2 miles",
      },
      {
        link: "https://goo.gl/maps/1NE5FAGMj7g7MDLm6",
        src: "/assets/images/198neigh2.webp",
        name: "Freeway Entrance to NYC - 2 miles",
      },
      {
        link: "https://goo.gl/maps/Ssnd66NoizhyK3AXA",
        src: "/assets/images/198neigh3.webp",
        name: "CTown Supermarket – 0.4 miles",
      },
      {
        link: "https://goo.gl/maps/2HMviaw6tyiR14v77",
        src: "/assets/images/198neigh4.webp",
        name: "Costambar Restaurant – 0.3 miles",
      },
      {
        link: "https://goo.gl/maps/XvEb3H9yVNC2xd6d6",
        src: "/assets/images/198neigh5.webp",
        name: "Paterson Public School 21 –  0.4 miles",
      },
      {
        link: "https://goo.gl/maps/aQUnjnhPvg4Evc3v9",
        src: "/assets/images/198neigh6.webp",
        name: "Wrigley park – 0.2 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage425,
    category: "425eleventh",
    city: "Paterson, NJ 07514",
    address: "425 11th Avenue",
    price1: "$1,700",
    price2: "$2,000",
    area1: "604 Sq. Ft and up",
    amenities1: "1 Bathroom / 1 Parking",
    area2: "915 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Taking applications now. Open 6/15. Located in Paterson’s East Side, th Avenueth Ave has a central community area on each floor and 24/7 security surveillance for added safety.",
    highlight2:
      "This modern apartment complex offers one and two bedroom apartments that come with walk-in closets for personal belongings and HVAC systems to keep you cool during summer months!",
    featureList1: [
      "Community area on each floor",
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Entrance lobby",
      "Package room",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern, one and two bedroom residences have ample storage, contemporary appliances and materials, and HVAC!  Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR425,
    floorPlanImage2: twoBR425,
    nearByContent:
      "Convenient to highways and public transportation into NYC th Avenueth Ave is located in Paterson’s East Side district. Walking distance to shopping and close to Eastside Park.",
    photoGalleryImages: [
      Gallery425One,
      Gallery425Two,
      Gallery425Three,
      Gallery425Four,
    ],
    center: [
      {
        lat: 40.92211357343122,
        lng: -74.14962114440603,
        name: "425 11th Avenue",
      },
    ],
    similarProperty: [
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage198,
        name: "198 Lafayette st",
        propertyName: "198lafayette",
        neighbour: "RIVERSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage216,
        name: "216 spring st",
        propertyName: "216springst",
        neighbour: "SOUTH PATERSON",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage185,
        name: "185 Broadway",
        propertyName: "185broadway",
        neighbour: "SAND HILL",
      },
    ],

    nearByLocation: [
      {
        link: "https://goo.gl/maps/LPdaSqAp9tnB48w89",
        src: "/assets/images/198neigh1.webp",
        name: "Paterson Station – 1.4 miles",
      },
      {
        link: "https://goo.gl/maps/WZBh7r4VCBBHtNXt8",
        src: "/assets/images/198neigh2.webp",
        name: "Freeway Entrance to NYC - 2 miles",
      },
      {
        link: "https://goo.gl/maps/w5kVR3tVuK2BcYEy5",
        src: "/assets/images/neigh3.webp",
        name: "Eastside Park – 1 mile",
      },
      {
        link: "https://goo.gl/maps/NkWfiNfkUTwNwzrL8",
        src: "/assets/images/neigh4.webp",
        name: "2000 Restaurant – 0.3 miles",
      },
      {
        link: "https://goo.gl/maps/mrCQidboQPYWsDM39",
        src: "/assets/images/neigh5.webp",
        name: "Paterson Public School 26 –  0.4 miles",
      },
      {
        link: "https://goo.gl/maps/HQE3QdiJeBz98DiT9",
        src: "/assets/images/neigh6.webp",
        name: "Memorial Pool – 1.7 miles",
      },
      {
        link: "https://goo.gl/maps/BGGNKn16imX1rB1R8",
        src: "/assets/images/neigh7.webp",
        name: "Dunkin Donuts– 0.5 miles",
      },
      {
        link: "https://goo.gl/maps/GR8vxd4bZZpTkqGo8",
        src: "/assets/images/198neigh3.webp",
        name: "CTown Supermarket –  0.5 miles",
      },
      {
        link: "https://goo.gl/maps/11wpxvqZcn6uxGhJ7",
        src: "/assets/images/neigh9.webp",
        name: "Barnert Hospital – 0.6 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage216,
    category: "216springst",
    city: "Paterson, NJ 07503",
    address: "216 spring st",
    price1: "$1,750",
    price2: "$2,200",
    area1: "707 Sq. Ft and up",
    amenities1: "1 Bathroom / 1 Parking",
    area2: "919 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Welcome to 216 Spring Street. With modern comforts such as on-floor laundry rooms and quartz counters in each residence, 216 Spring Street adds 24/7 security surveillance for added security and a community room on each floor for shared events with neighbors.",
    highlight2:
      "This modern apartment complex offers one and two bedroom residences which come with walk-in closets and HVAC systems to keep you cool during the summer and warm in winter.",
    highlight3:
      "Come live in a community that is designed for the modern lifestyle.",
    featureList1: [
      "Community area on each floor",
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Entrance lobby",
      "Package room",
      "Laundry room on each floor",
      "Bike storage",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable and modern, one and two bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR216,
    floorPlanImage2: twoBR216,
    nearByContent:
      "Convenient to highways and public transportation into NYC, 216 Spring Street is located near downtown Paterson. Walking distance to shopping and close to schools, the Medical Center and Garret Mountain.",
    photoGalleryImages: [
      Gallery216One,
      Gallery216Two,
      Gallery216Three,
      Gallery216Four,
    ],
    center: [
      {
        lat: 40.90565717544739,
        lng: -74.16857382416788,
        name: "216 Spring Street",
      },
    ],
    similarProperty: [
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage185,
        name: "185 Broadway",
        propertyName: "185broadway",
        neighbour: "SAND HILL",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage425,
        name: "425 11th Ave",
        propertyName: "425eleventh",
        neighbour: "EASTSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage198,
        name: "198 Lafayette",
        propertyName: "198lafayette",
        neighbour: "RIVERSIDE",
      },
    ],

    nearByLocation: [
      {
        link: "https://goo.gl/maps/pnpTcW2HYq7Qpv449",
        src: "/assets/images/198neigh1.webp",
        name: "Paterson Station – 0.7 miles",
      },
      {
        link: "https://goo.gl/maps/kXY9CgfkS5SmLe3M6",
        src: "/assets/images/216neigh2.webp",
        name: "Freeway Entrance to NYC - 0.2 miles",
      },
      {
        link: "https://goo.gl/maps/q5vnyKbWDwgmTBiU7",
        src: "/assets/images/216neigh3.webp",
        name: "Lambert Tower - Garret Mountain – 1.9 miles",
      },
      {
        link: "https://goo.gl/maps/V1RPfUyVwbj9MRb5A",
        src: "/assets/images/216neigh4.webp",
        name: "Rifle Camp Park – 2.4 miles",
      },
      {
        link: "https://goo.gl/maps/A45VUVwQNwq2BjqB6",
        src: "/assets/images/216neigh5.webp",
        name: "Paterson Public School 3 –  0.4 miles",
      },
      {
        link: "https://g.page/PatersonGreatFalls",
        src: "/assets/images/216neigh6.webp",
        name: "Paterson Great Falls National Historical Park – 1.3 miles",
      },
      {
        link: "https://goo.gl/maps/7jySjX5xQzKTwGPE6",
        src: "/assets/images/216neigh7.webp",
        name: "Dunkin Donuts– 0.4 miles",
      },
      {
        link: "https://goo.gl/maps/KmRyacgqA3ryHL8y9",
        src: "/assets/images/216neigh8.webp",
        name: "Walgreens –  0.4 miles",
      },
      {
        link: "https://goo.gl/maps/eojPjrpYku6vM3k29",
        src: "/assets/images/216neigh9.webp",
        name: "St. Joseph’s University Medical Center – 0.2 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage449,
    category: "449East-18th-St",
    city: "Paterson, NJ 07524",
    address: "449 EAST 18TH ST",
    price1: "$1,800",
    price2: "$2,250",
    area1: "613 Sq. Ft and up",
    amenities1: "1 Bathroom / 1 Parking",
    area2: "932 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Centrally located near downtown Paterson, 449 East 18th St is a fresh apartment complex that offers one and two bedroom apartments.",
    highlight2:
      "With laundry facilities, walk-in closet and HVAC to keep you cool during summer months, it's perfect for anyone looking for easy access to public transportation,local shops and groceries, museums, schools, churches and more.",
    featureList1: [
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Bike storage",
      "Laundry room",
      "Playground on site",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Walk in closets",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern – one and two bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR449,
    floorPlanImage2: twoBR449,
    photoGalleryImages: [
      gallery449_1,
      gallery449_2,
      gallery449_3,
      gallery449_4,
    ],
    nearByContent:
      "Live here. Go anywhere. 449 East 18th St is ideally located a quick walk to the train stop and close to schools, restaurants, downtown and more. The Paterson Station is also nearby.",
    center: [
      {
        lat: 40.92469536278817,
        lng: -74.15349506179874,
        name: "449 East 18th St",
      },
    ],
    similarProperty: [
      {
        type: "Studio and 1 Bedroom",
        image: bannerImage159,
        name: "159 12th Ave",
        propertyName: "159-12th-Ave",
        neighbour: "WRIGLEY PARK",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage425,
        name: "425 11th Ave",
        propertyName: "425eleventh",
        neighbour: "EASTSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage198,
        name: "198 Lafayette",
        propertyName: "198lafayette",
        neighbour: "RIVERSIDE",
      },
    ],
    nearByLocation: [
      {
        link: "https://goo.gl/maps/88qJHPArfUd2EjBv7",
        src: "/assets/images/route20.png",
        name: "Route 20 – 0.9 miles",
      },
      {
        link: "https://goo.gl/maps/3cj9zg52ZHRW7muX9",
        src: "/assets/images/198neigh3.webp",
        name: "CTown Supermarket – 0.3 miles",
      },
      {
        link: "https://goo.gl/maps/hqq8aYvx7iFhs7g48",
        src: "/assets/images/eastsidePark.png",
        name: "Eastside Park – 1.2 miles",
      },
      {
        link: "https://goo.gl/maps/RFszxEuAM9RGP8HC8",
        src: "/assets/images/198neigh1.webp",
        name: "Paterson Station – 0.9 miles",
      },
      {
        link: "https://goo.gl/maps/VgvH3GcRDXpsWBLd6",
        src: "/assets/images/dunkin.png",
        name: "Dunkin’ Donuts – 0.7 miles",
      },
      {
        link: "https://goo.gl/maps/G7v3fRKo4szcPAuV9",
        src: "/assets/images/parkway.png",
        name: "NJ Parkway – 1.5 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage154,
    category: "154-Hamilton-Ave",
    city: "Paterson, NJ 07501",
    address: "154 Hamilton Ave",
    price1: "$1,500",
    price2: "$1,750",
    studio: true,
    area1: "445 Sq. Ft and up",
    amenities1: "1 Parking",
    area2: "566 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Centrally located near downtown Paterson, 154 Hamilton Ave is a fresh apartment complex that offers studio and one bedroom apartments.",
    highlight2:
      "With laundry facilities, walk-in closet and HVAC to keep you cool during summer months, it's perfect for anyone looking for easy access to public transportation,local shops and groceries, museums, schools, churches and more.",
    featureList1: [
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Bike storage",
      "Entrance lobby",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern – studio and one bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR154,
    // floorPlanImage2: twoBR185,
    photoGalleryImages: [
      gallery154_1,
      gallery154_2,
      gallery154_3,
      gallery154_4,
    ],
    nearByContent:
      "Live here. Go anywhere. 154 Hamilton Ave is ideally located a quick walk to the train stop and close to schools, restaurants, downtown and more. The soon to be fully renovated Hinchliffe Stadium is also nearby.",

    center: [
      {
        lat: 40.92004173958986,
        lng: -74.16521365273822,
        name: "154 Hamilton Ave",
      },
    ],
    similarProperty: [
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage425,
        name: "425 11th Ave",
        propertyName: "425eleventh",
        neighbour: "RIVERSIDE",
      },
      {
        type: "1 Bedroom",
        image: bannerImage34,
        name: "36 Auburn St",
        propertyName: "36-Auburn-St",
        neighbour: "EASTSIDE",
      },
      {
        type: "Studio and Bedroom",
        image: bannerImage159,
        name: "159 12th Ave",
        propertyName: "159-12th-Ave",
        neighbour: "WRIGLEY PARK",
      },
    ],
    nearByLocation: [
      {
        link: "https://goo.gl/maps/3tbD5U9vUwhDZgeb9",
        src: "/assets/images/198neigh4.webp",
        name: "Costambar (Domincan Restaurant) – 0.3 miles",
      },
      {
        link: "https://goo.gl/maps/uLfazPqGrBhJyVoC6",
        src: "/assets/images/GoldenMangoSuperMarket.png",
        name: "Golden Mango Supermarket – 0.2 miles",
      },
      {
        link: "https://goo.gl/maps/EtWLULdZuDbh5awa9",
        src: "/assets/images/img-6.png",
        name: "Hinchcliff Stadium – 0.9 miles",
      },
      {
        link: "https://goo.gl/maps/UgJktaHe9JDJ4Mp66",
        src: "/assets/images/198neigh1.webp",
        name: "NJ Transit Train – 0.5 miles",
      },
      {
        link: "https://goo.gl/maps/GW7q8StfQfmyKDTi9",
        src: "/assets/images/wendy.png",
        name: "Wendy’s – 0.4 miles",
      },
      {
        link: "https://goo.gl/maps/FdNCAwAafgjid4PU8",
        src: "/assets/images/img-5.png",
        name: "Passaic County Community College – 0.3 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage159,
    category: "159-12th-Ave",
    city: "Paterson, NJ 07501",
    address: "159 12th Ave",
    price1: "$1,500",
    price2: "$1,750",
    studio: true,
    area1: "445 Sq. Ft and up",
    amenities1: "1 Parking",
    area2: "566 Sq. Ft and up",
    amenities2: "1 Bathroom / 1 Parking",
    highlight1:
      "Centrally located near downtown Paterson, 159 12th Ave is a fresh apartment complex that offers studio and one bedroom apartments.",
    highlight2:
      "With laundry facilities, walk-in closet and HVAC to keep you cool during summer months, it's perfect for anyone looking for easy access to public transportation,local shops and groceries, museums, schools, churches and more.",
    featureList1: [
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Bike storage",
      "Entrance lobby",
      "Package room",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern – studio and one bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual units vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR154,
    // floorPlanImage2: twoBR185,
    photoGalleryImages: [
      gallery159_1,
      gallery159_2,
      gallery159_3,
      gallery159_4,
    ],
    nearByContent:
      "Live here. Go anywhere. 159 12th Ave is ideally located a quick walk to the train stop and close to schools, restaurants, downtown and more. The NJ Transit Train Station is also nearby.",
    center: [
      {
        lat: 40.921538425719,
        lng: -74.15856260000001,
        name: "159-12th-Ave",
      },
    ],
    similarProperty: [
      {
        type: "1 Bedroom",
        image: bannerImage34,
        name: "36 Auburn St",
        propertyName: "36-Auburn-St",
        neighbour: "EASTSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage425,
        name: "425 11th Ave",
        propertyName: "425eleventh",
        neighbour: "EASTSIDE",
      },
      {
        type: "Studio and Bedroom",
        image: bannerImage154,
        name: "154 Hamilton Ave",
        propertyName: "154-Hamilton-Ave",
        neighbour: "EASTSIDE",
      },
    ],

    nearByLocation: [
      {
        link: "https://goo.gl/maps/FHeUMQkrVWbi8kBf6",
        src: "/assets/images/McDonald.png",
        name: "McDonald’s – 0.5 miles",
      },
      {
        link: "https://goo.gl/maps/hqq8aYvx7iFhs7g48",
        src: "/assets/images/eastsidePark.png",
        name: "Eastside Park – 1.2 miles",
      },
      {
        link: "https://goo.gl/maps/zUHYjFRAszP394JB8",
        src: "/assets/images/198neigh3.webp",
        name: "CTown Supermarket – 0.8 miles",
      },
      {
        link: "https://goo.gl/maps/PddsEMhPwPqs9gp16",
        src: "/assets/images/wendy.png",
        name: "Wendy’s – 1.1 miles",
      },
      {
        link: "https://goo.gl/maps/UgJktaHe9JDJ4Mp66",
        src: "/assets/images/198neigh1.webp",
        name: "NJ Transit Train – 0.5 miles",
      },
      {
        link: "https://goo.gl/maps/U5tXQkAF4rSeGUqC7",
        src: "/assets/images/216neigh9.webp",
        name: "St. Joseph Medical Center – 1.9 miles",
      },
    ],
  },
  {
    bannerImage: bannerImage34,
    category: "36-Auburn-St",
    city: "Paterson, NJ 07501",
    address: "36 Auburn St",
    price1: "$1,800",
    // price2: "$1,750",
    area1: "725 Sq. Ft and up",
    // area2: "566 Sq. Ft and up",
    amenities1: "1 Bathroom / 1 Parking",
    // amenities2: "1 Parking",
    highlight1:
      "Centrally located near downtown Paterson, 36 Auburn St is a fresh apartment complex that offers one bedroom apartments.",
    highlight2:
      "With laundry facilities, walk-in closet and HVAC to keep you cool during summer months, it's perfect for anyone looking for easy access to public transportation, local shops and groceries, museums, schools, churches and more.",
    featureList1: [
      "24/7 video surveillance",
      "Optional paid parking spot",
      "Bike storage",
      "Entrance lobby",
      "Package room",
    ],
    featureList2: [
      "HVAC",
      "Quartz counters",
      "Stainless steel appliances",
      "Gas stove",
      "Vinyl flooring",
    ],
    floorPlanContent:
      "Comfortable, modern – one bedroom residences have ample storage, contemporary appliances and materials, and HVAC! Individual  vary, but most are similar in layout to what is shown below.",
    floorPlanImage1: oneBR36,
    // floorPlanImage2: twoBR185,
    photoGalleryImages: [gallery36_1, gallery36_2, gallery36_3, gallery36_4],
    nearByContent:
      "Live here. Go anywhere. 36 Auburn St is ideally located a quick walk to the train stop and close to schools, restaurants, downtown and more. The Passaic County Community College is also nearby.",
    center: [
      {
        lat: 40.92096898923331,
        lng: -74.16470595096845,
        name: "36 Auburn St",
      },
    ],
    similarProperty: [
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage449,
        name: "449 East 18th St",
        propertyName: "449East-18th-St",
        neighbour: "RIVERSIDE",
      },
      {
        type: "Studio and 1 Bedroom",
        image: bannerImage154,
        name: "154 Hamilton Ave",
        propertyName: "154-Hamilton-Ave",
        neighbour: "EASTSIDE",
      },
      {
        type: "1 and 2 Bedrooms",
        image: bannerImage185,
        name: "185 Broadway",
        propertyName: "185broadway",
        neighbour: "SAND HILL",
      },
    ],
    nearByLocation: [
      {
        link: "https://goo.gl/maps/gxytLWmZgX6kQvFg6",
        src: "/assets/images/gym.png",
        name: "The Workout Gym – 0.6 miles",
      },
      {
        link: "https://goo.gl/maps/63SwYZQWQXZLak2g8",
        src: "/assets/images/police.png",
        name: "Paterson Police – 0.4 miles",
      },
      {
        link: "https://goo.gl/maps/FdNCAwAafgjid4PU8",
        src: "/assets/images/img-5.png",
        name: "Passaic County Community College – 0.3 miles",
      },
      {
        link: "https://goo.gl/maps/PddsEMhPwPqs9gp16",
        src: "/assets/images/wendy.png",
        name: "Wendy’s – 0.4 miles",
      },
      {
        link: "https://goo.gl/maps/UgJktaHe9JDJ4Mp66",
        src: "/assets/images/198neigh1.webp",
        name: "NJ Transit Train – 0.7 miles",
      },
      {
        link: "https://goo.gl/maps/qniK5EC3MGwDhWTQ8",
        src: "/assets/images/I80.png",
        name: "I-80 – 1.2 miles",
      },
    ],
  },
];
