/**
 * home.js
 * copyright © 2022 Ania Management
 */
import React from "react";
import { Link } from "react-router-dom";
import banner1 from "../assets/images/banner1.png";
import banner01 from "../assets/images/banner01.jpeg";
import banner2 from "../assets/images/banner2.png";
import banner3 from "../assets/images/banner3.png";
import banner4 from "../assets/images/banner4.png";
import construction from "../assets/images/icon-construction.png";
import development from "../assets/images/icon-development.png";
import investment from "../assets/images/icon-investment.png";
import management from "../assets/images/icon-management.png";
import communityone from "../assets/images/image-community-1.png";
import communitytwo from "../assets/images/image-community-2.png";
import propertyone from "../assets/images/property-1.png";
import propertytwo from "../assets/images/property2.png";
import propertythree from "../assets/images/property3.png";
import propertyfour from "../assets/images/property-4.png";
import bannerImage449 from "../assets/images/banner449East.jpg";
import PropertiesDetail from "../pages/propertiesdetail";
import Management from "./managment";
import Development from "./development";
import Construction from "./construction";
import Investment from "./investment";
// import WhyPaterson from "./whyPaterson";
import AboutUs from "./aboutus";
import "../assets/css/home.css";

import NewSlideShow from "../components/newSlideShow";

const slideShowImages = [banner01, banner2, banner3];
const slideShowProperties = {
  duration: 4000,
  transitionDuration: 2000,
  defaultIndex: 0,
  autoplay: true,
  infinite: true,
  responsive: true,
  arrows: false,
  pauseOnHover: false,
  indicators: false,
};

function Home() {
  //for putting data of faq's to state on page load
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardData = [
    {
      cardName: "198lafayette",
      name: "198 Lafayette Street",
      url: propertyone,
    },
    { cardName: "185broadway", name: "185 Broadway", url: propertytwo },
    { cardName: "425eleventh", name: "425 11th Avenue", url: propertythree },
    { cardName: "216springst", name: "216 Spring Street", url: propertyfour },
    // {
    //   cardName: "449East-18th-St",
    //   name: "449 EAST 18TH ST",
    //   url: bannerImage449,
    // },
  ];

  return (
    <div className="main">
      <section className="content-wrapper">
        <div className="ania-banner">
          <NewSlideShow
            slideShowImages={slideShowImages}
            slideShowProperties={slideShowProperties}
          />
          {/* <div className="container-fluid px-lg-3 px-0">
            <div className="main-banner">
              <img
                src={banner}
                className="img-fluid img-main"
                alt="banner-img"
              />
              <p className="welcome">welcome to ania</p>
            </div>
          </div> */}
        </div>
        <div className="ania-services">
          <div className="container-width">
            <h2 className="section-title">ANIA Services</h2>
            <div className="services-row-first">
              <div className="services-block-wrapper">
                <div className="services-block">
                  <img
                    src={management}
                    className="services-img"
                    alt="service"
                  />
                  <p className="services-title">Management</p>
                  <p className="services-description">
                    Delivering a quality property management experience to
                    tenants.
                  </p>
                  <Link
                    className="services-learnmore"
                    to="/management"
                    component={<Management />}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="services-block-wrapper">
                <div className="services-block">
                  <img
                    src={construction}
                    className="services-img"
                    alt="service"
                  />
                  <p className="services-title">Construction</p>
                  <p className="services-description">
                    Building modern homes using local contractors and suppliers.
                  </p>
                  <Link
                    className="services-learnmore"
                    to="/construction"
                    component={<Construction />}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="services-row-second">
              <div className="services-block-wrapper">
                <div className="services-block">
                  <img
                    src={development}
                    className="services-img"
                    alt="service"
                  />
                  <p className="services-title">Development</p>
                  <p className="services-description">
                    Revitalizing neighborhoods to make them safe and
                    family-friendly.
                  </p>
                  <Link
                    className="services-learnmore"
                    to="/development"
                    component={<Development />}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="services-block-wrapper">
                <div className="services-block">
                  <img
                    src={investment}
                    className="services-img"
                    alt="service"
                  />
                  <p className="services-title">Investment</p>
                  <p className="services-description">
                    Value-focused investments that seek to improve communities.
                  </p>
                  <Link
                    className="services-learnmore"
                    to="/investment"
                    component={<Investment />}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ania-story">
          <div className="container-width">
            <div className="ania-story-1">
              <div className="content-right">
                <h2 className="section-title">Partnering with the community</h2>
                <Link
                  className="services-learnmore"
                  to="/about-us"
                  component={<AboutUs />}
                >
                  <button className="btn btn-outline-secondary story-btn">
                    See Our Story
                  </button>
                </Link>
              </div>
              <img src={communityone} className="img-left" alt="story-img" />
              <img src={communitytwo} className="img-right" alt="story-img" />
            </div>
          </div>
        </div>
        <div className="ania-featured-properties">
          <div className="container-width">
            <h2 className="section-title fproperty">Featured Properties</h2>
            <div className="row">
              {cardData && cardData.length > 0
                ? cardData.map((val, i) => (
                    <div key={i} className="col-lg-6">
                      <Link
                        to={`/property/${val.cardName}`}
                        component={<PropertiesDetail />}
                      >
                        <div className="featured-block">
                          <img
                            src={val.url}
                            className="img-fluid"
                            alt={val.name}
                          />
                          <div className="featured-caption">
                            <p className="featured-caption-text">{val.name}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
