//JSON data for about us page template
/**
 * prospectiveTenantsLayout.js
 * copyright © 2022 Ania Management
 */
import aboutusmobileimg from "./assets/images/prospectTenants.png";
import aboutusdesktopimg from "./assets/images/prospectTenants.png";

export const prospectiveTenantsdata = [
  {
    pageTitle: "Prospective Tenants",
    url: "prospective-tenants",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusmobileimg,
    title: "Hello! We are glad you are looking at your next home with us.",
    p1: "We strive to provide our tenants with modern and safe places to live. All of our units have 24/7 security surveillance to provide security. To keep our tenants warm in the winter and cool in the summer all units provide heating and air and all units in new buildings built with HVAC. Each unit that we offer for rent has a modern kitchen with quartz countertops and new appliances.  We want our neighborhoods to be healthy and have opportunities, so we are active within the community to support education, sports and safety.",
    p2: "To see available units, check out our available properties <u><a href='/properties'>here</a></u>.",
    p3: "Currently all rental applications are digitally entered in our office at 175 Broadway Paterson, NJ 07505. Phone <a href='tel:9735237690'>973-523-7690</a>",
    subHeading3: "Needed to apply",
    list1: {
      l1: "Proof of monthly net income (after taxes) which is 3x the rent amount over last two months",
      l2: "Original Social Security cards",
      l3: "Original photo ID",
    },
    subHeading4: "Bring to office",
    list2: {
      l1: "Proof of income: paystubs or SSI/SSD letters, pension and bank statements to prove income",
      l2: "Original Social Security Card",
      l3: "Original Photo ID: Drivers license, Passport or Green Card",
    },
    p4: "If prospective tenants have co-applicants they also need to submit a seperate application and provide all necessary documentation.",
    p5: "Prospective tenants will receive a decision if approved the same day for the most part unless it is after hours then it will be reviewed next business day.",
    p6: "Once approved, the new tenant should leave a deposit to secure the unit.",
    p7: "If a tenant moves mid-month they will need to pay a full month of rent and the second month will be prorated to reflect the credits.",
    p8: "Please check out our <u><a href='/faq-page'>FAQ</a></u> page for more information or <u><a href='/contact'>reach out</a></u> directly. For a list of available units, please check out our <u><a href='/find-unit'>Find a Unit</a></u> page.",
  },
];
