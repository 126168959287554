/**
 * navbar.js
 * copyright © 2022 Ania Management
 */
import React, { useState } from "react";
import logo from "../assets/images/AniaLogo-full.svg";
import Burger from "../components/burger";
import RightNav from "../components/rightNav";
import { Link } from "react-router-dom";
import Home from "../pages/home";
import ContactUs from "../pages/contactUs";
import AboutUs from "../pages/aboutus";
import Properties from "../pages/properties";
import FindUnit from "../pages/findaunit";
// import WhyPaterson from "../pages/whyPaterson"
import NewPatersonPage from "./newpatersonPage";
import "../assets/css/nav.css";

function Navbar() {
  //to manage default state for navbar is open or close
  const [status, setStatus] = useState("close");

  //to update default state for navbar
  const handleNav = () => {
    setStatus((prev) => (prev === "open" ? "close" : "open"));
  };
  return (
    <div className="navbar-main">
      <nav
        className={
          "navbar navbar-expand-custom navbar-light bg-white border-bottom " +
          (status === "open" ? "open-nav" : "close-nav")
        }
      >
        <div className={"container-fluid nav-max"}>
          <Link className="navbar-brand" to="/home" component={<Home />}>
            <img src={logo} alt="alt-img" />
          </Link>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mx-auto nav-ul">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/find-unit"
                  component={<FindUnit />}
                >
                  Find a Unit
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/properties"
                  component={<Properties />}
                >
                  Properties
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/whypaterson"
                  component={<NewPatersonPage />}
                >
                  Why Paterson
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about-us"
                  component={<AboutUs />}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  component={<ContactUs />}
                >
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="https://webapp.aniamanagement.com/"
                  className="nav-link"
                  style={{ color: "#007CBA" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Tenant Portal
                </a>
                {/* <Link
                  className="nav-link"
                  style={{ color: "#007CBA" }}
                  to="https://webapp.floriomanagement.com/"
                  component={<Home />}
                > */}
                {/* </Link> */}
              </li>
            </ul>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <Burger handleNav={handleNav} status={status} />
          </button>
          <RightNav handleNav={handleNav} status={status} />
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
