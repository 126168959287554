/**
 * rentalpage.js
 * copyright © 2022 Ania Management
 */
import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/inquiry.css";

import { apiCall } from "../components/apiManager";
import { normalizeInput } from "../components/numberFormatter";
import { Select, FormControl, MenuItem } from "@mui/material";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Modal, Spinner } from "react-bootstrap";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function RentalPage(props) {
  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    content: props?.data?.content,
    bedroomCount: "",
    agent: "",
  };

  //variables to store default data in state
  const [formValues, setFormValues] = useState(initialValues);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [agents, setAgents] = useState([]);

  let { fullName, email, phone, content, bedroomCount, agent } = formValues;

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      let response = await apiCall("get", `user/get-rental-agents`, null, {});
      setAgents(response);
    } catch (error) {
      console.error(error);
    }
  };

  //for handling captcha
  const handleVerify = async (response) => {
    if (!response) {
      setCaptchaVerified(true);
    }
    if (response && !captchaVerified) {
      setCaptchaVerified(true);
    }
  };

  //updating form value and state
  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "phone") {
      value = await normalizeInput(value, phone);
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  //for submiting form data
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      setError(true);
      let { fullName, email, phone, content, bedroomCount } = formValues;
      if (!fullName || !email || !phone || !bedroomCount || !captchaVerified) {
        return;
      }

      let payload = {
        ...formValues,
        bedroom: bedroomCount,
        ...props.data,
        zzid_USER: agent,
        USER_Name: agents.find(x=> x.zzid === agent)?.NameFull_used || ''
      };

      delete payload.agent

      if (payload) {
        let data = apiCall("post", "unit_property/rentalInquiry", payload, {});
        if (data) {
          setError(false);
          setCaptchaVerified(false);
          setFormValues({
            fullName: "",
            email: "",
            phone: "",
            bedroomCount: "",
            agent: ""
          });
          // toast.success("Inquiry Successfully submitted!");
          setShowThankYou(true);
        } else {
          toast.error(data.message, "Something bad happened");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="content-wrapper contact-us">
      <div className="ania-content pt-lg-1 pt-0 inquiry">
        <div className="container-width">
          <div className="main-content">
            <div className="row justify-content-center align-items-top">
              <div className="col-lg-12 px-lg-5 px-4 order-lg-2 order-2"></div>
              <div className="col-lg-7 order-lg-3 order-4 rental-form-title">
                <p>CONNECT WITH A RENTAL AGENT</p>
              </div>
              <div className="col-lg-8 order-lg-4 order-5">
                <GoogleReCaptchaProvider reCaptchaKey="6LdF5jceAAAAAMF1RMx5RaNMK9tke3RzRRGEEwX_">
                  <form className="ania-form">
                    <div className="mb-3">
                      <input
                        type="text"
                        name="fullName"
                        className={`form-control ${
                          error && !fullName ? "input-invalid" : ""
                        }`}
                        placeholder="Full Name"
                        id=""
                        value={fullName}
                        onChange={handleChange}
                        disabled={loading}
                      />
                      <p className="error-msg">
                        {error && !fullName && "Full Name is required"}
                      </p>
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="email"
                        className={`form-control ${
                          error && !email ? "input-invalid" : ""
                        }`}
                        placeholder="Your Email"
                        id=""
                        value={email}
                        onChange={handleChange}
                        disabled={loading}
                      />
                      <p className="error-msg">
                        {error && !email
                          ? "Email is required"
                          : error &&
                            email &&
                            !email.match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            )
                          ? "Incorrect email address"
                          : null}
                      </p>
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="phone"
                        className={`form-control ${
                          error && !phone ? "input-invalid" : ""
                        }`}
                        placeholder="Your Telephone"
                        id=""
                        value={phone}
                        onChange={handleChange}
                        disabled={loading}
                      />
                      <p className="error-msg">
                        {error && !phone
                          ? "Number is required"
                          : error && phone && phone.length < 14
                          ? "Invalid phone number"
                          : null}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <FormControl
                          className={`form-control ${
                            error && !bedroomCount ? "input-invalid" : ""
                          }`}
                        >
                          <label
                            htmlFor="bedroomCount"
                            className="bedroom-placeholder"
                            style={{
                              position: bedroomCount ? "" : "absolute",
                              display: bedroomCount ? "none" : "block",
                              color: "grey",
                              margin: "0",
                            }}
                          >
                            Bedroom Count
                          </label>
                          <Select
                            name="bedroomCount"
                            id="bedroomCount"
                            // className="border-0"
                            value={bedroomCount}
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            disabled={loading}
                            // displayEmpty
                          >
                            <MenuItem value={"1"}>
                              <div className="rental-form-control-options">
                                1
                              </div>
                            </MenuItem>
                            <MenuItem value={"2"}>
                              <div className="rental-form-control-options">
                                2
                              </div>
                            </MenuItem>
                            <MenuItem value={"3"}>
                              <div className="rental-form-control-options">
                                3
                              </div>
                            </MenuItem>
                            <MenuItem value={"4"}>
                              <div className="rental-form-control-options">
                                4
                              </div>
                            </MenuItem>
                            <MenuItem value={"5"}>
                              <div className="rental-form-control-options">
                                5+
                              </div>
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <p className="error-msg">
                          {error &&
                            !bedroomCount &&
                            "Bedroom Count is required"}
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <FormControl className={`form-control`}>
                          <label
                            htmlFor="agent"
                            className="agent-placeholder"
                            style={{
                              position: agent ? "" : "absolute",
                              display: agent ? "none" : "block",
                              color: "grey",
                              margin: "0",
                            }}
                          >
                            Agent
                          </label>
                          <Select
                            name="agent"
                            id="agent"
                            value={agent}
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            disabled={loading}
                          >
                            {agents?.map((x) => {
                              return (
                                <MenuItem value={x["zzid"]} key={x["zzid"]}>
                                  <div className="rental-form-control-options">
                                    {x["NameFull_used"]}
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* <div className="mb-3">
                      <textarea
                        type="text"
                        name="content"
                        className={`form-control ${error && !content ? 'input-invalid' : ''}`}
                        placeholder="I’m interested in (property Address)."
                        rows="7"
                        id=""
                        value={content}
                        onChange={handleChange}></textarea>
                      <p className="error-msg">
                        {error && !content && "Message is required"}
                      </p>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block btn-ania"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {loading ? <Spinner animation="border" /> : "Send"}
                        </button>
                        <p className="error-msg">
                          {error && !captchaVerified && "You are not human"}
                        </p>
                        <ToastContainer />
                      </div>
                    </div>
                    <Modal centered size="md" show={showThankYou}>
                      <Modal.Header
                        onHide={() => setShowThankYou(false)}
                        closeButton
                        className="border-0"
                      ></Modal.Header>
                      <Modal.Body className="d-flex flex-column align-items-center">
                        <TaskAltIcon
                          style={{ height: 100, width: 100, color: "#6ec36e" }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            marginTop: 10,
                          }}
                        >
                          Thank you for your interest.
                        </p>
                        <p style={{ fontSize: 14, textAlign: "center" }}>
                          A link has been sent to you to complete an online
                          rental application to continue the process.
                        </p>
                      </Modal.Body>
                    </Modal>
                    <div>
                      <GoogleReCaptcha onVerify={handleVerify} />
                    </div>
                  </form>
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default RentalPage;
